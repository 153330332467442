import React from 'react';
import IR from "../../assets/img/Services/Rectangle 4622.png";
import overlayImg from "../../assets/img/Login/Rectangle 4621.png";
import JobUpdates from '../../component/JobUpdates';
import Footer from '../../component/Footer';
import HeaderHome from '../../component/HeaderHome';
import Graphic from "../../../src/assets/img/Services/website infographic.jpg";


function InternationalRecruitment() {

  return (
    <>
      <HeaderHome />
      <div className="bg-[#0082DA]">
        <div className="md:mx-auto lg:mx-0 py-8 px-4 sm:px-6 lg:px-8">
  <div className="text-center md:text-start w-full lg:w-[70%] mx-auto lg:mx-0">
    <h1 className="text">Innovative Services and Solutions for a Healthier Tomorrow.</h1>
    {/* <p className="text-2">"Discover how cutting-edge technologies and patient-centered care are revolutionising healthcare services, improving outcomes, and ensuring a healthier future for all."</p> */}
  </div>
</div>

      </div>
      <div className="mt-16 mx-auto px-4 lg:px-[50px] justify-center w-full h-full">
        <div className="w-full text-left">
          <div data-aos="zoom-in" className="relative w-full flex items-center justify-center">
          <div className="relative w-full h-full flex items-center justify-center">
          <div className='absolute text-[#FFFFFF] text-[24px] sm:text-[50px] md:text-[70px] lg:text-[90px] font-normal z-20'>International Recruitment</div>
            <img src={IR} alt="Login" className='w-full h-full' />
            <div className="absolute inset-0 bg-transparent">
              <img src={overlayImg} alt="Overlay" className="rounded-xl w-full h-full" />
            </div>
          </div>
        </div>
        <h3 data-aos="fade-up" className="mt-12 font-bold text-5 mb-2 text-center"><span className="home-text">RHC 360 Solutions: HTE International Recruitment Services</span></h3>
        <div className="mt-4 w-full h-auto sm:w-3/4 sm:h-auto md:w-1/2 lg:w-2/3 mx-auto">
          <img src={Graphic} alt="Graphic" className="w-full h-auto object-cover" />
        </div>
          <p data-aos="zoom-in" className="text-3 mt-1 text-center">At RHC 360 Solutions, we are committed to providing exceptional international recruitment services in compliance with the HealthTrust Europe framework. Our approach aligns with the Total Workforce Solutions III framework, specifically under the National and International Permanent Recruitment (NIPR) lots.</p>
          <p data-aos="zoom-in" className="text-3 mt-12 font-bold text-center"><span className="home-text">Our Compliance with HealthTrust Europe Framework</span></p>
          <p data-aos="zoom-in" className="text-3 mt-12 font-bold text-center"><span className="home-text">Ethical Recruitment Practices</span></p>
          <p data-aos="zoom-in" className="text-3 mt-5">We prioritize ethical recruitment by sourcing candidates listed on the Ethical Recruitment List. Our recruitment processes are designed to align with the framework terms, ensuring that all international candidates meet the rigorous standards set by the Department of Health and Social Care.</p>
          <p data-aos="zoom-in" className="text-3 mt-8 font-bold text-center"><span className="home-text">Comprehensive Support Packages</span></p>
          <p data-aos="zoom-in" className="text-3 mt-5">We offer modular support packages for independent international recruitment activities. This flexibility allows participating authorities to utilize internal resources while receiving expert guidance from our team.</p>

          <p data-aos="zoom-in" className="text-3 mt-8 font-bold text-center"><span className="home-text">Recruitment Process Outsourcing (RPO)</span></p>
          <p data-aos="zoom-in" className="text-3 mt-5">RHC 360 Solutions provides Recruitment Process Outsourcing solutions tailored to the needs of healthcare providers. This includes the transfer of all or part of the recruitment process to our experienced team, enabling efficient and compliant recruitment of international candidates.</p>
          <p data-aos="zoom-in" className="text-3 mt-12 font-bold text-center"><span className="home-text">Statement of Work (SoW) Solutions</span></p>
          <p data-aos="zoom-in" className="text-3 mt-5">For specific projects, we offer Statement of Work provisions, allowing us to deliver specialized expertise to support the recruitment of international professionals. This method ensures transparency and accountability throughout the recruitment process.</p>
          <p data-aos="zoom-in" className="text-3 mt-12 font-bold text-center"><span className="home-text">Key Benefits</span></p>
          <ul data-aos="zoom-in" className="mt-5 ml-5 list-disc text-3">
            <li className="mb-2"><span className="font-bold">Free Access:</span> Our services under the HealthTrust Europe framework are free to access, ensuring no hidden costs for healthcare providers.</li>
            <li className="mb-2"><span className="font-bold">Market-Leading Compliance:</span> We adhere to strict compliance requirements, ensuring that all international candidates are qualified and meet the necessary healthcare standards.</li>
            <li className="mb-2"><span className="font-bold">Customizable Solutions:</span> Our modular service provision allows healthcare providers to select the specific services they require, enhancing both internal processes and cost control.</li>
            </ul>
          <p data-aos="zoom-in" className="text-3 mt-5">By leveraging our expertise in international recruitment and our commitment to compliance, RHC 360 Solutions ensures that healthcare organizations can effectively fill vacancies with qualified professionals while adhering to the highest standards of practice.</p>
          
            {/* <div data-aos="zoom-in" className='flex mt-12 justify-center'>
            <a href="/ContactUs" target="blank" className="bg-[#0082DA] text-2 font-bold py-2 px-5 lg:py-3 lg:px-6 rounded">Book an appointment</a>
          </div> */}
        </div>
      </div>
      <div className="mx-[40px] my-[100px]">
        <JobUpdates />
      </div>
      <Footer />
    </>
  );
}

export default InternationalRecruitment;
