import React from 'react';
import Cli from "../../assets/img/Services/Rectangle 4622.png";
import overlayImg from "../../assets/img/Login/Rectangle 4621.png";
import JobUpdates from '../../component/JobUpdates';
import Footer from '../../component/Footer';
import HeaderHome from '../../component/HeaderHome';
import { useNavigate } from 'react-router-dom';

function Clinical() {
    const navigate = useNavigate();

    const handleClickClinicalPermanent = () => {
        navigate('/JobsPage?Sector=Clinical&JobType=Permanent');
        window.scrollTo(0, 0);
      };

    const handleClickClinicalTemporary = () => {
        navigate('/JobsPage?Sector=Clinical&JobType=Contract');
        window.scrollTo(0, 0);
      };

  return (
    <>
      <HeaderHome />
      <div className="bg-[#0082DA]">
        <div className="md:mx-auto lg:mx-0 py-8 px-4 sm:px-6 lg:px-8">
  <div className="text-center md:text-start w-full lg:w-[70%] mx-auto lg:mx-0">
    <h1 className="text">Innovative Services and Solutions for a Healthier Tomorrow.</h1>
    {/* <p className="text-2">"Discover how cutting-edge technologies and patient-centered care are revolutionising healthcare services, improving outcomes, and ensuring a healthier future for all."</p> */}
  </div>
</div>

      </div>
      <div className="mt-16 mx-auto px-4 lg:px-[50px] justify-center w-full h-full">
        <div className="w-full">
          <div data-aos="zoom-in" className="relative w-full flex items-center justify-center">
          <div className="relative w-full h-full flex items-center justify-center">
          <div className='absolute text-[#FFFFFF] text-[24px] sm:text-[50px] md:text-[70px] lg:text-[90px] font-normal z-20'>Clinical</div>
            <img src={Cli} alt="Login" className='w-full h-full' />
            <div className="absolute inset-0 bg-transparent">
              <img src={overlayImg} alt="Overlay" className="rounded-xl w-full h-full" />
            </div>
          </div>
        </div>
          <h3 data-aos="fade-up" className="mt-12 font-bold text-5 mb-2 text-center"><span className="home-text">RHC Nursing and Midwifery Services (RM6181)</span></h3>
          <p data-aos="zoom-in" className="text-3 mt-12 text-center">At RHC, we understand the vital role of nursing and midwifery professionals in providing exceptional healthcare. Our RM6181 services support healthcare organizations in delivering high-quality patient care while ensuring the well-being of both patients and staff.</p>
          <p data-aos="zoom-in" className="text-3 mt-12 font-bold text-center"><span className="home-text">Comprehensive Nursing Services</span></p>
          <p data-aos="zoom-in" className="text-3 mt-5">We offer a wide range of nursing specialties to meet the specific needs of healthcare facilities:</p>
          <ul data-aos="zoom-in" className="mt-5 ml-5 list-disc text-3">
            <li className="mb-2"><span className="font-bold">Registered Nurses (RNs):</span> Skilled in various clinical settings, our RNs provide comprehensive assessments and care for complex patient needs.</li>
            <li className="mb-2"><span className="font-bold">Nurse Practitioners (NPs):</span> With advanced training, our NPs deliver high-quality, patient-centered care, including diagnosing conditions and managing care plans.</li>
            <li className="mb-2"><span className="font-bold">Specialist Nurses:</span> We provide access to specialist nurses in fields like pediatrics, geriatrics, and mental health, enhancing patient outcomes through tailored care.</li>
            </ul>
          <p data-aos="zoom-in" className="text-3 mt-12 text-center font-bold"><span className="home-text">Exceptional Midwifery Services</span></p>
          <p data-aos="zoom-in" className="text-3 mt-5">RHC is dedicated to supporting women during pregnancy and childbirth:</p>
          <ul data-aos="zoom-in" className="mt-5 ml-5 list-disc text-3">
            <li className="mb-2"><span className="font-bold">Certified Midwives:</span> Our midwives offer personalized care throughout pregnancy, labor, and postpartum, focusing on normal childbirth and family education.</li>
            <li className="mb-2"><span className="font-bold">Community Midwifery:</span> We provide community-based midwifery services for continuity of care in familiar settings.</li>
            <li className="mb-2"><span className="font-bold">Emergency Response:</span> Our midwives are trained to manage emergencies, ensuring prompt care for mothers and babies.</li>
            </ul>
          <p data-aos="zoom-in" className="text-5 mt-12 text-center font-bold">Why Choose <span className="home-text">RHC?</span></p>
          <ul data-aos="zoom-in" className="mt-5 ml-5 list-disc text-3">
            <li className="mb-2"><span className="font-bold">Quality Assurance:</span> We maintain rigorous practice standards and prioritize continuous improvement through regular training.</li>
            <li className="mb-2"><span className="font-bold">Holistic Care Approach:</span> Our professionals focus on the physical, emotional, and psychological well-being of patients.</li>
            <li className="mb-2"><span className="font-bold">Collaboration and Support:</span> Our teams work closely with other healthcare professionals for coordinated patient care.</li>
            <li className="mb-2"><span className="font-bold">Flexible Staffing Solutions:</span> We offer both temporary and permanent placements to meet fluctuating healthcare demands.</li>
            </ul>

          {/* <div data-aos="zoom-in" className='mt-12'>
        <button 
        onClick={handleClickClinicalPermanent} 
        className="bg-[#0082DA] text-2 font-bold py-2 px-5 lg:py-3 lg:px-6 rounded">
        Permanent Clinical Jobs
        </button>
        <div data-aos="zoom-in" className='mt-12'>
        <button 
        onClick={handleClickClinicalTemporary} 
        className="bg-[#0082DA] text-2 font-bold py-2 px-5 lg:py-3 lg:px-6 rounded">
        Temporary Clinical Jobs
        </button>
        </div>
    </div> */}
        </div>
        {/* <div className="mt-16 w-full text-center">
        <h3 data-aos="fade-up" className="font-bold text-5 mb-2"><span className="">Temporary Staffing</span></h3>
          <p data-aos="zoom-in" className="text-3 mt-12">RHC 360 Solutions has identified the significant strain on the UK NHS in employing skilled and experienced clinical professionals with qualifications that suit the current and future needs in the UK healthcare sector. In response we have devised a unique strategy to service the clinical recruitment needs efficiently and remain focused on delivering high-quality, accessible care to patients across the UK. </p> 
        </div> */}
      </div>
      <div className="mx-[40px] my-[100px]">
        <JobUpdates />
      </div>
      <Footer />
    </>
  );
}

export default Clinical;
