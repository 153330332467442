import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/Logo/Footer logo.jpg';
import locationIcon from '../assets/Icons/location.png';
import callIcon from '../assets/Icons/callIcon.png';
import mailIcon from '../assets/Icons/mailIcon.png';
import fb from '../assets/Icons/fb.png';
import insta from '../assets/Icons/Insta.png';
import Linkedin from '../assets/Icons/linkedin.jpg';
import tiktok from '../assets/Icons/tiktok.png';
import footerIcon from '../assets/Icons/image 6.png';
import AOHT from "../assets/Logo/AoHT-Member-Logo.png";
import CCS from "../assets/Logo/CCS-supplier-logo-blue-1.jpg.webp";
import CQC from "../assets/Logo/CQC-Registered-scaled.png";
import Cabinet from "../assets/Logo/Cabinet.png";
import CH from "../assets/Logo/Clinical-and-healthcare-staffing-supplier-badge-2.jpg";
import DC from "../assets/Logo/DC-Committed-logo_WEB-450x360.png";
import HTE from "../assets/Logo/HTE_logo_1line_CMYK_pos.webp";
import Homerton from "../assets/Logo/Homerton Click through.png";
import ISO from "../assets/Logo/ISO certification logo.png";
import INF from "../assets/Logo/Information_Commissioner's_Office_logo.svg.png";
import QCS from "../assets/Logo/QCS_logo_yellowicon_blueletters-1-1.png";
import REC from "../assets/Logo/rec-logo.jpg";
import SKILLS from "../assets/Logo/skills-for-care.png";
import '../css/Main.css';
import DownloadHtmlButton from '../component/DownloadButton';
import QR from "../assets/Logo/Ramsy QR.jpg";

const Footer = () => {
  return (
    <footer>
      <div className="bg-[#0082DA] text-[#FFFFFF] py-8">
        <div className="container mx-auto px-4 md:px-6 lg:px-8 xl:px-10">
        <div class="flex flex-col lg:flex-row justify-center lg:justify-between">
  <div class="w-full lg:w-auto mb-6 lg:mb-0 flex flex-col items-center lg:items-start">
    <div class="flex flex-col items-start">
    <a href="/">
    <img data-aos="zoom-in" src={logo} alt="Logo" className="h-28 md:h-36 lg:h-28 w-[250px] md:w-[350px] lg:w-[200px]" />
    </a>
    <div data-aos="zoom-in">      
      <a href="tel:+442035001100" class=" mt-5 flex items-center space-x-4">
        <img src={callIcon} alt="Call Icon" />
        <p className='text-2'>+44 (0)20 3500 1100</p>
      </a>
      <a href="mailto:bookings@rhc360.com" class="mt-5 flex items-center space-x-4">
        <img src={mailIcon} alt="Mail Icon" />
        <p className='text-2'>bookings@rhc360.com</p>
      </a>
      <a href="https://maps.google.com/?q=422 High Street North, London E12 6RH, UK" target="_blank" rel="noopener noreferrer" class="mt-5 flex items-center space-x-4">
        <img src={locationIcon} alt="Location Icon" />
        <p className='text-2'>422 High Street North, London E12 6RH, UK</p>
      </a>
      </div>
    </div>
  </div>
  <div data-aos="zoom-in" class="hidden lg:flex w-full lg:w-auto mb-6 lg:mb-0 flex flex-col items-start">
    <h4 class="font-bold mb-4 text-1">Quick Links</h4>
    <ul>
      <li><a href="/" class="hover:font-bold text-2">Home</a></li>
      <li className='mt-1'><a href="/Services/Clinical" class="hover:font-bold text-2">Services</a></li>
      <li className='mt-1'><a href="/AboutUs" class="hover:font-bold text-2">Clients</a></li>
      {/* <li className='mt-1'><a href="/JobsPage" class="hover:font-bold text-2">Careers</a></li> */}
      <li className='mt-1'><a href="/ContactUs" class="hover:font-bold text-2">Contact us</a></li>
      {/* <li className='mt-1'><a href="/ModernSlavery" class="hover:font-bold text-2">Modern Slavery</a></li> */}
      <li className='mt-1'><a href="/CarbonFootPrint" class="hover:font-bold text-2">Carbon Reduction Plan</a></li>
    </ul>
  </div>

  <div data-aos="zoom-in" class="hidden lg:flex w-full lg:w-auto mb-6 lg:mb-0 flex flex-col items-start">
    <h4 class="font-bold mb-4 text-1">Recruitment</h4>
    <ul>
      <li className='mt-1'><a href="/JobsPage" class="hover:font-bold text-2">Candidates</a></li>
      <li className='mt-1'><a href="/PrivacyPolicy" class="hover:font-bold text-2">Privacy Policy</a></li>
      <li className='mt-1'><a href="/TermsAndConditions" class="hover:font-bold text-2">Terms & Conditions</a></li>
    </ul>
  </div>

  <div data-aos="zoom-in" class="hidden lg:flex w-full lg:w-auto mb-6 lg:mb-0 flex flex-col items-start">
    <h4 class="text-1 font-bold mb-4">Stay In Touch</h4>
    <div class="flex space-x-6 mb-6 lg:mb-0">
    <a href="https://www.facebook.com/share/nJyyGdKL4BcNEqmL/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
      <img src={fb} alt="Fb Icon" className="h-6 w-6 cursor-pointer" />
    </a>
    <a href="https://www.instagram.com/rhc360solutions?igsh=MXhzb3VzODFpaDljeA%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">
      <img src={insta} alt="Insta Icon" className="h-6 w-6 cursor-pointer" />
    </a>
    <a href="https://www.linkedin.com/company/ramsy-health-care/" target="_blank" rel="noopener noreferrer">
      <img src={Linkedin} alt="Twitter Icon" className="h-6 w-6 cursor-pointer" />
    </a>
    <a href="https://www.tiktok.com/@rhc.360.solutions?_t=8pFlULvvo8e&_r=1" target="_blank" rel="noopener noreferrer">
      <img src={tiktok} alt="Twitter Icon" className="h-6 w-6 cursor-pointer" />
    </a>
    </div>
    <DownloadHtmlButton/>
    <div className='mt-2'>
      <img src={QR} alt="Twitter Icon" className="h-36 w-36 cursor-pointer" />
    </div>
</div>

</div>
<div data-aos="zoom-in" className="flex lg:hidden">
<div class="w-full lg:w-auto mb-6 lg:mb-0 flex flex-col items-start">
    <h4 class="font-bold mb-4 text-[14px] sm:text-[18px] ">Quick Links</h4>
    <ul>
      <li><a href="/" class="hover:font-bold">Home</a></li>
      <li className='mt-1'><a href="/Services/Clinical" class="hover:font-bold text-2">Services</a></li>
      <li className='mt-1'><a href="/AboutUs" class="hover:font-bold text-2">Clients</a></li>
      {/* <li className='mt-1'><a href="/JobsPage" class="hover:font-bold text-2">Careers</a></li> */}
      <li className='mt-1'><a href="/ContactUs" class="hover:font-bold text-2">Contact us</a></li>
      {/* <li className='mt-1'><a href="/ModernSlavery" class="hover:font-bold text-2">Modern Slavery</a></li> */}
      <li className='mt-1'><a href="/CarbonFootPrint" class="hover:font-bold text-2">Carbon Reduction Plan</a></li>
    </ul>
    <div className="flex justify-center">
  <DownloadHtmlButton/>
  </div>
  </div>
<div className='flex flex-col'>
  <div class="w-full lg:w-auto mb-6 lg:mb-0 flex flex-col items-start">
    <h4 class="font-bold mb-4 text-[14px] sm:text-[18px] ">Recruitment</h4>
    <ul>
      <li className='mt-1'><a href="/JobsPage" class="hover:font-bold text-2">Candidates</a></li>
      <li className='mt-1'><a href="/PrivacyPolicy" class="hover:font-bold text-2">Privacy Policy</a></li>
      <li className='mt-1'><a href="/TermsAndConditions" class="hover:font-bold text-2">Terms & Conditions</a></li>
    </ul>
  </div>
  <div className="mb-6 lg:mb-0 flex flex-col items-start">
  <h4 className="text-[14px] sm:text-[18px] font-bold mb-4">Stay In Touch</h4>
  <div className="flex space-x-6 mb-6 lg:mb-0">
    <a href="https://www.facebook.com/share/nJyyGdKL4BcNEqmL/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
      <img src={fb} alt="Fb Icon" className="h-6 w-16 md:w-12 cursor-pointer" />
    </a>
    <a href="https://www.instagram.com/rhc360solutions?igsh=MXhzb3VzODFpaDljeA%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">
      <img src={insta} alt="Insta Icon" className="h-6 w-12 md:w-10 cursor-pointer" />
    </a>
    <a href="https://www.linkedin.com/company/ramsy-health-care/" target="_blank" rel="noopener noreferrer">
      <img src={Linkedin} alt="Twitter Icon" className="h-6 w-12 md:w-10 cursor-pointer" />
    </a>
    <a href="https://www.tiktok.com/@rhc.360.solutions?_t=8pFlULvvo8e&_r=1" target="_blank" rel="noopener noreferrer">
      <img src={tiktok} alt="Twitter Icon" className="h-6 w-12 md:w-12 cursor-pointer" />
    </a>
  </div>
  <div className='my-4 lg:hidden flex justify-center'>
      <img src={QR} alt="Twitter Icon" className="h-20 w-20 cursor-pointer" />
    </div>

</div>
</div>
</div>

        </div>
        <div className="container mx-auto px-4 md:px-6 lg:px-8 xl:px-10">
        <div className="mt-0 lg:mt-8 container mx-auto px-4 md:px-6 lg:px-8 xl:px-10">
  <div data-aos="fade-up" className="flex flex-wrap justify-center gap-4 mt-auto">
    <div>
      <img src={AOHT} alt="AOHT Logo" className="w-[200px] h-[100px] object-contain"/>
    </div>
    <div>
      <img src={CCS} alt="CCS Logo" className="w-[200px] h-[100px] object-contain"/>
    </div>
    <Link href="https://www.cqc.org.uk/location/1-12428243146" target="_blank">
      <img src={CQC} alt="CQC Logo" className="w-[200px] h-[100px] object-contain"/>
    </Link>
    <div>
      <img src={Cabinet} alt="Cabinet Logo" className="w-[200px] h-[100px] object-contain"/>
    </div>
    <div>
      <img src={CH} alt="CH Logo" className="w-[200px] h-[100px] object-contain"/>
    </div>
    <div>
      <img src={DC} alt="DC Logo" className="w-[200px] h-[100px] object-contain"/>
    </div>
  </div>
  <div data-aos="fade-up" className="flex flex-wrap justify-center gap-4 mt-4">
  <div>
      <img src={Homerton} alt="Homerton Logo" className="w-[200px] h-[100px] object-contain"/>
    </div>
    <div>
      <img src={ISO} alt="ISO Logo" className="w-[200px] h-[100px] object-contain"/>
    </div>
    <div>
      <img src={INF} alt="INF Logo" className="w-[200px] h-[100px] object-contain"/>
    </div>
    <div>
      <img src={QCS} alt="QCS Logo" className="w-[200px] h-[100px] object-contain"/>
    </div>
    <div>
      <img src={REC} alt="REC Logo" className="w-[200px] h-[100px] object-contain"/>
    </div>
    <div>
      <img src={SKILLS} alt="SKILLS Logo" className="w-[200px] h-[100px] object-contain"/>
    </div>
  </div>
  <div data-aos="fade-up" className="flex flex-wrap justify-center gap-4 mt-4">
  <div>
      <img src={HTE} alt="HTE Logo" className="w-[200px] h-[100px] object-contain"/>
    </div>
    </div>
</div>

        </div>
      </div>
      <div className="bg-[#363535]">
        <div className="mx-auto flex justify-center items-center py-4 px-0 sm:px-4 md:px-6 lg:px-8 xl:px-10">
          <div className="text-center">
          <a href="/" class="flex items-center space-x-4">
        <img src={footerIcon} alt="Footer Icon" />
        <p className='text-[13px] sm:text-[18px] text-[#FFFFFF]'>Copyright &copy; 2024 RHC 360 Solutions. All rights reserved.</p>
      </a>            
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
