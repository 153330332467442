import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/Logo/logo.png';
import locationIcon from '../assets/Icons/location.png';
import callIcon from '../assets/Icons/callIcon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import "../css/Main.css";
import { AuthContext } from '../component/AuthContext';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

const HeaderHome = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { isAuthenticated, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenServices, setIsDropdownOpenServices] = useState(false);
  const [isDropdownOpenCandidates, setIsDropdownOpenCandidates] = useState(false);
  const dropdownRefCandidates = useRef(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRefProfile = useRef(null);
  const dropdownRefServices = useRef(null);



  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const toggleDropdownServices = () => {
    setIsDropdownOpenServices(!isDropdownOpenServices);
  };

  const closeDropdownServices = () => {
    setIsDropdownOpenServices(false);
  };
  const handleMouseLeave = (e) => {
    if (
      dropdownRefServices.current && 
      e.relatedTarget instanceof Node && 
      !dropdownRefServices.current.contains(e.relatedTarget)
    ) {
      closeDropdownServices();
    }
  };

  const toggleDropdownCandidates = () => {
    setIsDropdownOpenCandidates(!isDropdownOpenCandidates);
  };

  const closeDropdownCandidates = () => {
    setIsDropdownOpenCandidates(false);
  };

  const handleMouseLeaveCandidates = (e) => {
    if (
      dropdownRefCandidates.current &&
      e.relatedTarget instanceof Node && 
      !dropdownRefCandidates.current.contains(e.relatedTarget)
    ) {
      closeDropdownCandidates();
    }
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRefProfile.current &&
      event.target instanceof Node &&
      !dropdownRefProfile.current.contains(event.target)
    ) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Function to handle option selection and redirection
  const handleOptionClick = (path,sector) => {
    navigate(path);
    setSelectedOption(null);
  };

  


  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header>
      <div className="bg-[#0082DA] text-white">
        <div className="container mx-auto flex justify-between items-center py-4 px-4 sm:px-0 md:px-4 xl:px-10">
          <div className="flex flex-col lg:flex-row items-start lg:items-center space-y-3 lg:space-y-0 lg:space-x-4 text-sm lg:text-base w-[55%] lg:w-[80%]">
            <Link to="https://maps.google.com/?q=422 High Street North, London E12 6RH, UK" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-4 mr-5">
              <img data-aos="fade-up" src={locationIcon} alt="Location Icon" />
              <p data-aos="fade-up" >422 High Street North, London E12 6RH, UK</p>
            </Link>
            <Link to="tel:+442035001100" className="flex items-center space-x-4">
              <img data-aos="fade-up" src={callIcon} alt="Call Icon" />
              <p data-aos="fade-up" >+44 (0)20 3500 1100</p>
            </Link>
            <div data-aos="fade-up">
            <Link to="/ModernSlavery" target='blank' className="lg:hidden text-[16px] text-[#FFFFFF]">Modern Slavery</Link>
            </div>
          </div>

          <div className="lg:hidden">
            <button onClick={toggleMenu} className="text-[#FFFFFF] focus:outline-none">
              <FontAwesomeIcon icon={isOpen ? faTimes : faBars} className="h-6 w-6" />
            </button>
          </div>
          {isOpen && (
            <div className="lg:hidden">
              <ul className="nav flex flex-col">
                <li className={location.pathname === '/' ? 'active' : ''}>
                  <Link to="/" className="lg:mx-3 hover-bold text-[16px] text-[#FFFFFF]">Home</Link>
                </li>
                <li className={(location.pathname === '/Services/Clinical' || location.pathname === '/Services/NonClinical'  || location.pathname === '/Services/Domiciliary'  || location.pathname === '/Services/SupportedLiving'  || location.pathname === '/Services/InternationalRecruitment'  || location.pathname === '/Services/Trainings')  ? 'font-bold' : ''} ref={dropdownRefServices}>
      <div className="relative">
        <button
          onMouseOver={toggleDropdownServices}
          onMouseLeave={handleMouseLeave}
          className={`lg:mx-3 hover:text-[#0082DA] text-[16px]`}
        >
          Services<FontAwesomeIcon icon={dropdownVisible ? faCaretUp : faCaretDown} className="ml-2" />
        </button>
        {isDropdownOpenServices && (
          <div ref={dropdownRefServices} className="absolute bg-white w-60 shadow-md rounded-md z-10 right-0"
          onMouseLeave={handleMouseLeave}>
          <ul>
            <li>
              <button onClick={() => handleOptionClick('/Services/Clinical')} className={`block px-4 pt-4 pb-3 hover:text-[#0082DA] text-[16px] text-[#323b4b] ${location.pathname === '/Services/Clinical' ? 'active' : 'NoActive'}`}>
                Clinical
              </button>
            </li>
            <li>
              <button onClick={() => handleOptionClick('/Services/NonClinical')} className={`block px-4 pb-3 hover:text-[#0082DA] text-[16px] text-[#323b4b] ${location.pathname === '/Services/NonClinical' ? 'active' : 'NoActive'}`}>
                Non Clinical
              </button>
            </li>
            <li>
              <button onClick={() => handleOptionClick('/Services/Domiciliary')} className={`block px-4 pb-3 hover:text-[#0082DA] text-[16px] text-[#323b4b] ${location.pathname === '/Services/Domiciliary' ? 'active' : 'NoActive'}`}>
                Domiciliary care
              </button>
            </li>
            <li>
              <button onClick={() => handleOptionClick('/Services/SupportedLiving')} className={`block px-4 pb-3 hover:text-[#0082DA] text-[16px] text-[#323b4b] ${location.pathname === '/Services/SupportedLiving' ? 'active' : 'NoActive'}`}>
                Supported Living
              </button>
            </li>
            <li>
              <button onClick={() => handleOptionClick('/Services/InternationalRecruitment')} className={`block px-4 pb-3 hover:text-[#0082DA] text-[16px] text-[#323b4b] ${location.pathname === '/Services/InternationalRecruitment' ? 'active' : 'NoActive'}`}>
                International recruitment
              </button>
            </li>
            <li>
              <button onClick={() => handleOptionClick('/Services/Trainings')} className={`block px-4 pb-4 hover:text-[#0082DA] text-[16px] text-[#323b4b] ${location.pathname === '/Services/Trainings' ? 'active' : 'NoActive'}`}>
                Trainings
              </button>
            </li>
          </ul>
          </div>
        )}
      </div>
    </li>
                <li className={(location.pathname === '/JobsPage' || location.pathname === '/JobspageInternational')  ? 'font-bold' : ''} ref={dropdownRefCandidates}>
      <div className="relative">
        <button 
          onMouseOver={toggleDropdownCandidates}
          onMouseLeave={handleMouseLeaveCandidates}
          className={`lg:mx-3 hover:text-[#0082DA] text-[16px]`}>Candidates<FontAwesomeIcon icon={dropdownVisible ? faCaretUp : faCaretDown} className="ml-2" /></button>
        {isDropdownOpenCandidates && (
          <div ref={dropdownRefCandidates} className="absolute top-full left-0 bg-white shadow-md rounded-md"
          onMouseLeave={handleMouseLeaveCandidates}>
          <ul>
            <li>
              <button onClick={() => handleOptionClick('/JobsPage')} className={`block px-4 pt-4 pb-2 hover:text-[#0082DA] text-[16px] text-[#323b4b] ${location.pathname === '/JobsPage' ? 'active' : 'NoActive'}`}>
                Domestic
              </button>
            </li>
            <li>
              <button onClick={() => handleOptionClick('/JobspageInternational')} className={`block px-4 pb-4 hover:text-[#0082DA] text-[16px] text-[#323b4b] ${location.pathname === '/JobspageInternational' ? 'active' : 'NoActive'}`}>
                International
              </button>
            </li>
          </ul>
          </div>
        )}
      </div>
    </li>
                <li className={location.pathname === '/AboutUs' ? 'active' : ''}>
                  <Link to="/AboutUs" className="lg:mx-3 hover-bold text-[16px] text-[#FFFFFF]">Clients</Link>
                </li>
                <li className={location.pathname === '/ContactUs' ? 'active' : ''}>
                  <Link to="/ContactUs" className="lg:mx-3 hover-bold text-[16px] text-[#FFFFFF]">Contact Us</Link>
                </li>
                <li className={location.pathname === '/Blog' ? 'active' : ''}>
                <Link to="/Blog" className="lg:mx-3 hover-bold text-[16px] text-[#FFFFFF]">Blog</Link>
              </li>
              </ul>
            </div>
          )}
          <div data-aos="fade-up" className="hidden lg:flex space-x-4 text-sm md:text-base">
            <Link to="/ModernSlavery" target="blank" className=" text-[16px] text-[#FFFFFF]">Modern Slavery</Link>
          </div>
        </div>
      </div>
      <div className="py-0 container mx-auto px-4 md:px-6 lg:px-8 xl:px-10">
        <div className="flex items-center justify-between">
          <Link to="/">
            <img src={logo} alt="Logo" data-aos="zoom-in"  className="h-24 md:h-32 lg:h-44 w-[150px] md:w-[200px] lg:w-[200px] xl:w-[300px]" />
          </Link>
          <div className="hidden lg:flex flex-grow text-center justify-around">
  <ul className="nav flex flex-wrap lg:space-x-4 xl:space-x-10 font-semibold">
    <li className={location.pathname === '/' ? 'active' : ''}>
      <Link to="/" className={`lg:mx-3 hover:text-[#0082DA] text-[16px] ${location.pathname === '/' ? 'text-[#0082DA]' : 'text-[#323b4b]'}`}>Home</Link>
    </li>
    <li className={(location.pathname === '/Services/Clinical' || location.pathname === '/Services/NonClinical'  || location.pathname === '/Services/Domiciliary'  || location.pathname === '/Services/SupportedLiving'  || location.pathname === '/Services/InternationalRecruitment'  || location.pathname === '/Services/Trainings')  ? 'active' : ''} ref={dropdownRefServices}>
      <div className="relative">
        <button
          onMouseOver={toggleDropdownServices}
          onMouseLeave={handleMouseLeave}
          className={`lg:mx-3 hover:text-[#0082DA] text-[16px]`}
        >
          Services<FontAwesomeIcon icon={dropdownVisible ? faCaretUp : faCaretDown} className="ml-2" />
        </button>
        {isDropdownOpenServices && (
          <div ref={dropdownRefServices} className="absolute top-full left-0 bg-white w-80 shadow-md rounded-md z-10"
          onMouseLeave={handleMouseLeave}>
          <ul>
            <li>
              <button onClick={() => handleOptionClick('/Services/Clinical')} className={`block px-4 pt-4 pb-3 hover:text-[#0082DA] text-[16px] text-[#323b4b] ${location.pathname === '/Services/Clinical' ? 'active' : 'NoActive'}`}>
                Clinical
              </button>
            </li>
            <li>
              <button onClick={() => handleOptionClick('/Services/NonClinical')} className={`block px-4 pb-3 hover:text-[#0082DA] text-[16px] text-[#323b4b] ${location.pathname === '/Services/NonClinical' ? 'active' : 'NoActive'}`}>
                Non Clinical
              </button>
            </li>
            <li>
              <button onClick={() => handleOptionClick('/Services/Domiciliary')} className={`block px-4 pb-3 hover:text-[#0082DA] text-[16px] text-[#323b4b] ${location.pathname === '/Services/Domiciliary' ? 'active' : 'NoActive'}`}>
                Domiciliary care
              </button>
            </li>
            <li>
              <button onClick={() => handleOptionClick('/Services/SupportedLiving')} className={`block px-4 pb-3 hover:text-[#0082DA] text-[16px] text-[#323b4b] ${location.pathname === '/Services/SupportedLiving' ? 'active' : 'NoActive'}`}>
                Supported Living
              </button>
            </li>
            <li>
              <button onClick={() => handleOptionClick('/Services/InternationalRecruitment')} className={`block px-4 pb-3 hover:text-[#0082DA] text-[16px] text-[#323b4b] ${location.pathname === '/Services/InternationalRecruitment' ? 'active' : 'NoActive'}`}>
                International recruitment
              </button>
            </li>
            <li>
              <button onClick={() => handleOptionClick('/Services/Trainings')} className={`block px-4 pb-4 hover:text-[#0082DA] text-[16px] text-[#323b4b] ${location.pathname === '/Services/Trainings' ? 'active' : 'NoActive'}`}>
                Trainings
              </button>
            </li>
          </ul>
          </div>
        )}
      </div>
    </li>
    
    <li className={(location.pathname === '/JobsPage' || location.pathname === '/JobspageInternational')  ? 'active' : ''} ref={dropdownRefCandidates}>
      <div className="relative">
        <button 
          onMouseOver={toggleDropdownCandidates}
          onMouseLeave={handleMouseLeaveCandidates}
          className={`lg:mx-3 hover:text-[#0082DA] text-[16px]`}>Candidates<FontAwesomeIcon icon={dropdownVisible ? faCaretUp : faCaretDown} className="ml-2" /></button>
        {isDropdownOpenCandidates && (
          <div ref={dropdownRefCandidates} className="absolute top-full left-0 bg-white shadow-md rounded-md z-10"
          onMouseLeave={handleMouseLeaveCandidates}>
          <ul>
            <li>
              <button onClick={() => handleOptionClick('/JobsPage')} className={`block px-4 pt-4 pb-2 hover:text-[#0082DA] text-[16px] text-[#323b4b] ${location.pathname === '/JobsPage' ? 'active' : 'NoActive'}`}>
                Domestic
              </button>
            </li>
            <li>
              <button onClick={() => handleOptionClick('/JobspageInternational')} className={`block px-4 pb-4 hover:text-[#0082DA] text-[16px] text-[#323b4b] ${location.pathname === '/JobspageInternational' ? 'active' : 'NoActive'}`}>
                International
              </button>
            </li>
          </ul>
          </div>
        )}
      </div>
    </li>
    <li className={location.pathname === '/AboutUs' ? 'active' : ''}>
      <Link to="/AboutUs" className={`lg:mx-3 hover:text-[#0082DA] text-[16px] ${location.pathname === '/AboutUs' ? 'text-[#0082DA]' : 'text-[#323b4b]'}`}>Clients</Link>
    </li>
    <li className={location.pathname === '/ContactUs' ? 'active' : ''}>
      <Link to="/ContactUs" className={`lg:mx-3 hover:text-[#0082DA] text-[16px] ${location.pathname === '/ContactUs' ? 'text-[#0082DA]' : 'text-[#323b4b]'}`}>Contact Us</Link>
    </li>
    <li className={location.pathname === '/Blog' ? 'active' : ''}>
      <Link to="/Blog" className={`lg:mx-3 hover:text-[#0082DA] text-[16px] ${location.pathname === '/Blog' ? 'text-[#0082DA]' : 'text-[#323b4b]'}`}>Blog</Link>
    </li>
  </ul>
</div>
          <div data-aos="zoom-in" className="flex flex-col md:flex-row justify-start items-center">
            {isAuthenticated ? (
              <>
                <div className="relative inline-block text-left" ref={dropdownRefProfile}>
      <span 
        onClick={toggleDropdown} 
        className="font-bold text-[#323b4b] cursor-pointer"
      >
        Welcome!<FontAwesomeIcon icon={dropdownVisible ? faCaretUp : faCaretDown} className="ml-2" />
      </span>

      {dropdownVisible && (
        <div className="absolute right-0 mt-2 w-28 bg-white shadow-lg rounded-md">
          <button 
            onClick={logout} 
            className="text-sm sm:text-base bg-[#0082DA] text-white font-bold py-1 px-1 rounded w-full text-center"
          >
            Log Out
          </button>
        </div>
      )}
    </div>
              </>
            ) : (
              <>
                <div className="hidden lg:flex space-x-2 sm:space-x-4 text-sm md:text-base items-center font-semibold">
                  <Link to="/SignIn" className="sm:text-base text-[16px] hover:text-[#0082DA] text-[#323b4b]">Sign In</Link>
                  <Link to="/SignUp" className="text-sm sm:text-base bg-[#0082DA] hover:bg-blue-700 text-white font-bold py-1 px-2 rounded">Sign Up</Link>
                </div>
                <div className="lg:hidden flex space-x-2 justify-end sm:space-x-4 text-sm md:text-base items-center">
                  <Link to="/SignIn" className="sm:text-base text-[16px] hover:text-[#0082DA] text-[#323b4b]">Sign In</Link>
                  <Link to="/SignUp" className="text-sm sm:text-base bg-[#0082DA] hover:bg-blue-700 text-white font-bold py-1 px-2 rounded">Sign Up</Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderHome;
