import React from 'react';
import BlogImg from "../assets/img/Blog/Rectangle 4622.png";
import Footer from '../component/Footer';
import HeaderHome from '../component/HeaderHome';
import JobUpdates from '../component/JobUpdates';

const Blog = () => {

  return (
    <>
      <HeaderHome />
      <div className="bg-[#0082DA]">
        <div className="md:mx-[10px] mx-auto py-8 px-4 sm:px-6 lg:px-8">
          <div className="my-8 text-center md:text-start w-full lg:w-[70%]">
            <h1 className="text mb-4">Navigating Life in a Care Home: Insights and Stories</h1>
            <p className="text-2">Welcome to our blog, where we share heartwarming stories, expert advice, and essential tips for life in a care home. From health and wellness to personal anecdotes, we're here to support you and your loved ones on this journey of care and connection.</p>
          </div>
        </div>
      </div>
      <div className="my-[70px] mx-[50px] shadow-lg rounded-xl flex flex-col justify-center">
        <div className="relative w-full flex items-center justify-center">
          <div data-aos="zoom-in" className="relative w-full h-full flex items-center justify-center">
            <img src={BlogImg} alt="Login" className='w-full h-full' />
          </div>
        </div>
        <div className="mt-12 mx-auto px-8 py-8">
          <p data-aos="fade-up" className='text-6 font-bold'>UPDATE WILL COME SOON</p>
        {/* <p data-aos="fade-up" className="font-bold text-3">19th June 2024</p>
        <p data-aos="zoom-in" className="font-bold text-[24px] sm:text-[36px]">Important Update: NHS 2024/25 Priorities and Operational Planning Guidance</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">We want to bring to your attention a significant change announced in the NHS 2024/25 priorities and operational planning guidance. By July 2024, NHS trusts are required to cease using off-framework agencies for engaging agency workers. This change aims to enhance governance and financial management across the NHS.</p>
        <p data-aos="fade-up" className="mt-12 font-bold text-[20px] sm:text-[24px]">What Does This Mean for You?</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">If you are currently working through an off-framework supplier, you will need to transition to a framework agency before the July deadline. Rest assured, Ramsy Health Care Agency is a framework-registered agency committed to supporting you through this transition.</p>
        <p data-aos="fade-up" className="mt-8 font-bold text-[20px] sm:text-[24px]">Our Existing Nurses</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">We understand that changes like these can be concerning. However, we want to assure you that as a nurse already associated with Ramsy Health Care Agency, you will remain unaffected. Our framework status ensures that you can continue your invaluable work without disruption.</p>
        <p data-aos="fade-up" className="mt-8 font-bold text-[20px] sm:text-[24px]">Support for New Nurses</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">For those nurses who are currently engaged with off-framework suppliers, we are here to help. Joining Ramsy Health Care Agency means you will not only stay compliant with the new NHS regulations but also gain access to numerous benefits that enhance your professional experience and financial stability.</p>
        <p data-aos="fade-up" className="mt-8 font-bold text-[20px] sm:text-[24px]">Why Choose Ramsy Health Care Agency?</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">At Ramsy Health Care Agency, with years of experience, we ensure a seamless transition to working with us while meeting the new NHS guidelines and enjoying a host of career-supporting benefits.</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">We provide customized solutions to help you navigate regulatory changes and optimize your professional journey. Beyond employment, we support you in multiple ways, including regular webinars aimed at improving the lives of key workers.</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">For more information on the NHS 2024/25 priorities and operational planning guidance, and to understand how these changes might affect you, feel free to reach out to us. We are dedicated to helping you continue your essential work with minimal disruption and maximum support.</p> */}
      </div>
      </div>
      <div className="mx-[40px] my-[50px]">
        <JobUpdates/>
        </div>
      <Footer />
    </>
  );
};

export default Blog;
